




























import {
  BButton, BModal, BForm, BFormInput, BFormGroup, BCard, BAlert, BLink, BCardText, BRow, BCol, BSpinner, BFormFile
} from 'bootstrap-vue'
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import Draggable from 'vuedraggable';
import DraggableUPOptionItem from './shared/DraggableOptionItem.vue';
import PreferencesUtilisateurSearchableColumns from './preferences/_SearchableColumns.vue';
import PreferencesUtilisateurActionsColumns from './preferences/_ActionsColumns.vue'
import store from '@/store/index'
import { CreatePreferenceUtilisateurModel } from '@/api/models/preferencesUtilisateur/preferenceUtilisateur';
import usePreferencesUtilisateur from '@/auth/usePreferencesUtilisateur';
import { TypePreference } from '@/api/models/enums/typePreference';
@Component({
  components: {
    BCard,
    BAlert,
    BLink,
    BRow,
    BCol,
    BCardText,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    Draggable,
    DraggableUPOptionItem,
    PreferencesUtilisateurSearchableColumns,
    PreferencesUtilisateurActionsColumns,
    BFormFile
  }
})
export default class PreferencesUtilisateur extends Vue {


  mainLoading: boolean = false;
  modalLoading: boolean = false;

  preferenceLoad:boolean = false;

  columnsPreferences: [] = [];
  userPreferences: [] = [];
  listTypesInactive: [] = [];

  actionsColumns: any = [];
  searchableColumns: any = [];

  async upload() {
    await this.$http.ressifnet.typeContrats.uploadFile(this.file)
      .then((res: any) => console.log(res))
  }

  file: any = null;

  async created() {
    this.loadUserPreferences();
  }
  
  async loadUserPreferences() {
    this.mainLoading = true;
      this.userPreferences = store.getters['user/user_preferences'];
      this.mainLoading = false;

      if (!this.userPreferences.length) {
        this.$store.dispatch('user/saveUserPreference', {
          key : "searchableColumns",
          value : "[]",     
        });              

        this.$store.dispatch('user/saveUserPreference', {
          key : "actionsColumns",
          value : "[]",     
        });                  
               
        // console.log('passe dans le else', this.userPreferences)
        // this.searchableColumns = this.userPreferences.find((value: any) => {
        //   return value.key == 'searchableColumns'
        // });

        // this.actionsColumns = this.userPreferences.find((value: any) => {
        //   return value.key == 'actionsColumns'
        // });
    }
  }
  
  onMoveInactif(operations: any) {
    this.onMove(operations, false);
  }

  onMoveActif(operations: any) {
    this.onMove(operations, true);
  }

  onMove(operations: any, active: boolean) {
    if (operations.hasOwnProperty("moved")) {
      // let el = this
      //   .listTypes
      //   .items
      //   .find(x => x.id == operations.moved.element.id)

      //   if(el) {
      //     el.ordre = operations.moved.newIndex + 1;
      //     this.selectedTypeContrats = el;
      //     this.confirmUpdate();
      //   } 
    }
    //added
    if (operations.hasOwnProperty("added")) {
      // let el = this
      //   .listTypes
      //   .items
      //   .find(x => x.id == operations.added.element.id);

      // if (el) {
      //   el.actif = active;
      //   el.ordre = operations.added.newIndex + 1;
      //   this.selectedTypeContrats = el;
      //   this.confirmUpdate();
      // } 
    }
  }
}
