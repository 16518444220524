















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class DraggableUPOptionItem extends Vue {
  @Prop({ required: true }) optionItem!: any
 
}
