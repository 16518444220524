





































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";
import {
  BFormSelect,
  BFormSelectOption,
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BFormTextarea
} from "bootstrap-vue";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue"
import RessifnetDateInput from "@/components/inputs/RessifnetDateInput.vue";
import { PreferenceUtilisateur } from '@/api/models/preferencesUtilisateur/preferenceUtilisateur'
import Draggable from 'vuedraggable';
import { successAlert, errorAlert } from "@/libs/sweetAlerts/alerts";
import DraggableUPOptionItem from '../shared/DraggableOptionItem.vue';
import store from '@/store/index';
import usePreferencesUtilisateur from '@/auth/usePreferencesUtilisateur'

@Component({
  components: {
    ValidationProvider,
    BFormDatepicker,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BFormSelect,
    BFormSelectOption,
    BFormTextarea,
    SearchableVueSelect,
    Draggable,
    DraggableUPOptionItem,
    RessifnetDateInput
  },
  name: "preferences-utilisateur-actions-columns",
})
export default class PreferencesUtilisateurActionsColumns extends Vue {
  listTypesActive: any = [];
  listTypesInActive: any = [];
  listColumnsHidden: any = [];
  

  columnsPreferences = [
    {
      "key": "dateAction",
      "sortable": true,
      "sortKey": "dateAction",
      "libelle": "Date de l'action"
    },
    {
      "key": "createur",
      "sortable": false,
      "sortKey": "createur",
      "libelle": "Créateur"
    },
    {
      "key": "contratLibelle",
      "sortable": false,
      "sortKey": "contratLibelle",
      "libelle": "Contrat"
    },
    {
      "key": "typesInterventions",
      "sortable": false,
      "sortKey": "typesInterventions",
      "libelle": "Types d'intervention"
    },
    {
      "key": "naturesInterventions",
      "sortable": false,
      "sortKey": "naturesInterventions",
      "libelle": "Nature d'intervention"
    },
    {
      "key": "sousDomainesInterventions",
      "sortable": false,
      "sortKey": "sousDomainesInterventions",
      "libelle": "Sous domaines"
    },
    {
      "key": "details",
      "sortable": false,
      "sortKey": "details",
      "libelle": "Détails"
    }
  ];
  
  get userPreferences() {
    return this.$store.state.user.user_preferences
  }
  get preference() {
    return this.userPreferences.find((value: any) => {
      return value.key == 'actionsColumns'
    });
  }
  
  @Watch('preference')
  preferenceChanged() {
    this.loadViewColumns();    
  }

  async loadViewColumns(){
    let parseValue = [];
    this.listTypesInActive = [...this.columnsPreferences];
    if (this.preference) {
      if (this.preference.value) {
          parseValue = JSON.parse(this.preference.value);
      } else {
         parseValue = [];
      }
    }  
    if (parseValue.length > 0) {
      this.listTypesActive = parseValue;
      this.listColumnsHidden = this.getDifference(this.listTypesInActive, parseValue);
    } else {
      this.listTypesActive = this.columnsPreferences;
    }
  }

  async created(){
    this.loadViewColumns();
  }

  parse(data: any) {
    if (data.value) {
      var array = JSON.parse(data.value.replaceAll("'", '"'));
      if (!Array.isArray(array)) {
        return Object.values(array);
      }
      return array;
    } else {
      return [];
    }
  }

  getDifference(array1: [], array2: []) {
    return array1.filter((object1:any) => {
      return !array2.some((object2:any) => {
        return object1.key === object2.key;
      });
    });
  }

  async updatePreference() {
    let payload = {...this.preference};
    payload.value = JSON.stringify(this.listTypesActive);
    payload.key = "actionsColumns"
    await this.$http.ressifnet.preferencesUtilisateur.post(
      payload
    ).then(async (response: any) => {
      this.$store.dispatch('user/fetchPreferencesUtilisateur');
      this.loadViewColumns();
      successAlert.fire({
        title: 'Modification d\'une preference utilisateur',
        text: 'Modification effectuée avec succès',
      })

    }, (error: any) => {
      errorAlert.fire({
        title: "Erreur lors de la mise à jour de la preference utilisateur",
      });
    });
  }

  onMoveInactif(operations: any) {
    this.onMove(operations, false);
  }

  onMoveActif(operations: any) {
    this.onMove(operations, true);
  }

  onMove(operations: any, active: boolean) {
    if (operations.hasOwnProperty("removed")) {
      let el: any = this
        .columnsPreferences
        .find(x => x.key == operations.removed.element.key)
   
      if (el) {
        this.listTypesActive.pop();
      }
    }
    //added
    if (operations.hasOwnProperty("added")) {
      let el: any = this
        .columnsPreferences
        .find(x => x.key == operations.added.element.key)

      if (el) {
        const col = {
          key: el.key,
          sortable: el.sortable,
          sortKey: el.sortKey,
          libelle: el.libelle
        };
        this.listTypesActive.push(col);
      }
    }
  }

}
